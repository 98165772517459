import type { ReactNode } from "react"

import { DialogTrigger } from "@intergamma/dialog"
import { media } from "@intergamma/theme"

import styled from "styled-components"

import { Link } from "~/components/Link"

import type { LoggedInNavigationItem } from "../hooks/useLoggedInNavigationItems"

interface NavigationItemProps {
  item: LoggedInNavigationItem
  children: ReactNode
}

export function NavigationLink({ item, children }: NavigationItemProps) {
  if ("external" in item && item.external) {
    return (
      <a href={item.url} className="flex h-auto w-full items-center leading-6">
        {children}
      </a>
    )
  }

  if ("url" in item) {
    return (
      <StyledLink to={item.url} $isActive={item.isActive}>
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledDialogTrigger to="#" type="button" $isActive={item.isActive}>
      {children}
    </StyledDialogTrigger>
  )
}

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  border: none;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: flex;
  padding: 0;
  height: auto;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:focus {
    outline: 0;
    border: none;
    box-shadow: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border: none;
    text-decoration: none;
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    `
      color: ${theme.colors.primary};
      font-weight: 700;
      border: none;
      text-decoration: none;

    `}

  ${media.md`
    font-weight: 400;
    ${({ $isActive }) => $isActive && "font-weight: 700"}
  `}
`

const StyledDialogTrigger = styled(StyledLink).attrs({
  as: DialogTrigger,
})``
