import { createHttpClient } from "@intergamma/account"

import type { Store } from "./types"

export const PAGE_SIZE = 10

export function createStoresService(CHECKOUT_URL: string) {
  const baseClient = createHttpClient(`${CHECKOUT_URL}/api/store`, {
    withCredentials: true,
  })

  return {
    searchStores(query: string, limit = 8): Promise<Store[] | null> {
      return baseClient.get(`search/${encodeURI(query)}?limit=${limit}`)
    },
  }
}
