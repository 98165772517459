// TODO: Move redirects to Hosting instead of client side redirects
export const karweiNlRedirects = {
  "/my/mijn-gegevens": "/my/mijn-gegevens/persoonlijke-gegevens",
  "/my/personal-information": "/my/mijn-gegevens/persoonlijke-gegevens",
  "/my/preferences": "/my/mijn-gegevens/nieuwsbrief",
  "/my/change-email": "/my/mijn-gegevens/e-mailadres-wijzigen",
  "/my/change-password": "/my/mijn-gegevens/wachtwoord-wijzigen",
  "/my/loyaltycards": "/my/mijn-gegevens/club-karwei-kaart",
  "/my/orders": "/my/mijn-bestellingen",
  "/my/remove-account": "/my/verzoek-tot-verwijderen-account",
  "/my/loyaltyMutations": "/my/loyalty-transactie-overzicht",
  "/my/orderstatus": "/my/bestelstatus-opvragen",
  "/my/logout": "/my/uitloggen",
  "/my/forgot-password": "/my/nieuw-wachtwoord-aanvragen",
  "/my/password-recovery": "/my/nieuw-wachtwoord-aanvragen/verificatie",
  "/my/register-loyalty-card": "/my/club-karwei-kaart/registreren",
  "/my/register-loyalty-card/activate": "/my/club-karwei-kaart/activeer",
  "/my/giftcard": "/my/cadeaupas",
  "/my/request-return": "/my/aanmelden-retour",
  "/my/newsletter/subscribe": "/my/nieuwsbrief",
  "/my/service-and-contact/contact": "/my/service-en-contact/contact",
  "/my/success/register": "/my/account-aanmaken-bevestigd",
  "/my/service-en-contact": "/my/service-en-contact/contact",
  "/my/login": "/my/inloggen",
  "/my/confirm-email": "/my/e-mailadres-bevestigen",
  "/my/profile": "/my/mijn-gegevens/persoonlijke-gegevens",
  "/my/profile/edit/email": "/my/mijn-gegevens/persoonlijke-gegevens/e-mailadres-wijzigen",
  "/my/profile/edit/newsletters": "/my/mijn-gegevens/nieuwsbrief",
  "/my/profile/edit/personal_info": "/my/mijn-gegevens/persoonlijke-gegevens",
  "/my/profile/edit/password": "/my/mijn-gegevens/wachtwoord-wijzigen",
  "/my/profile/edit/loyaltycards": "/my/mijn-gegevens/gamma-voordeelpas",
  "/my/password_recovery": "/my/nieuw-wachtwoord-aanvragen/verificatie",
  "/my/order": "/my/bestelstatus-opvragen",
  "/my/return": "/my/aanmelden-retour",
  "/my/giftcard/showBalance": "/my/cadeaupas",
  "/my/verificatie-nieuwsbrief-aanvraag": "/my/verificatie/newsletter",
  "/my/club-karwei-kaart": "/my/mijn-gegevens/club-karwei-kaart",
  "/my/purchases": "/my/mijn-aankopen",
}
