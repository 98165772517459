import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"

import { useSavedConfigurations } from "~/features/saved-configurations/hooks/useSavedConfigurations"
import { useIsMobile } from "~/features/shared/hooks/useIsMobile"

import type { Icon } from "~/components/icons"

import { getLanguage } from "~/helpers/getLanguage"
import { hasValue } from "~/helpers/hasValue"

import { useMyAccountConfig } from "~/config/MyAccountConfigContext"

export function useLoggedInNavigationItems(): LoggedInNavigationItem[] {
  const isMobile = useIsMobile()
  const { t, i18n } = useTranslation(["urls", "common", "logged-in-navigation"])

  const { urls } = useMyAccountConfig()
  const { data } = useSavedConfigurations()
  const savedConfigurations = data ?? []

  const language = getLanguage(i18n.language) === "fr" ? "fr" : "nl"
  const { pathname } = useLocation()

  const unfilteredNav: UnfilteredNav = [
    !isMobile
      ? {
          url: t("urls:/myaccount"),
          name: t("common:MyAccount"),
          icon: "Star",
        }
      : null,
    savedConfigurations.length > 0
      ? {
          url: t("urls:/saved-configurations"),
          name: t("logged-in-navigation:savedConfigurations"),
          icon: "Tool",
        }
      : null,
    {
      url: t("urls:/profile/loyaltycards"),
      name: t("logged-in-navigation:loyaltyCard"),
      icon: "CreditCard",
    },
    {
      url: t("urls:/loyaltyMutations"),
      name: t("logged-in-navigation:loyaltyOverview"),
      icon: "Chart",
    },
    {
      url: t("urls:/purchases"),
      name: t("logged-in-navigation:purchases"),
      icon: "Package",
    },
    {
      url: t("urls:/profile/personal-information"),
      name: t("logged-in-navigation:profile"),
      icon: "User",
    },
    {
      url: t("urls:/profile/newsletter"),
      name: t("logged-in-navigation:newsletter"),
      icon: "Chat",
    },
    {
      url: urls[language]?.privacy ?? "/",
      name: t("logged-in-navigation:privacy"),
      icon: "Eye",
      external: true,
    },
    {
      url: urls[language]?.customerService ?? "/",
      name: t("logged-in-navigation:contact"),
      icon: "Service",
      external: true,
    },
  ]

  return unfilteredNav.filter(hasValue).map((item) => ({
    ...item,
    isActive: "url" in item ? isNavigationItemActive(item.url, pathname, item.url === t("urls:/myaccount")) : false,
  }))
}

function isNavigationItemActive(url: string, pathname: string, isUrlDashboard: boolean) {
  if (isUrlDashboard) {
    return url === pathname
  }

  return url ? pathname.includes(url) : false
}

export interface LoggedInNavigationItemLink {
  url: string
  name: string
  icon: Icon
  isActive: boolean
  external: true
}

export interface LoggedInNavigationItemButton {
  name: string
  icon: Icon
  isActive: boolean
}

type UnfilteredNav = (
  | Omit<LoggedInNavigationItemButton, "isActive">
  | Omit<LoggedInNavigationItemLink, "isActive">
  | null
)[]

export type LoggedInNavigationItem = LoggedInNavigationItemButton | LoggedInNavigationItemLink
