import { u as e } from "./useActiveVariant-E1LtEXNi.js";
import { useMemo as c } from "react";
import { experiments as a } from "./config.js";
function u(t) {
  if (!(t in a))
    throw Error(`Experiment "${t}" not found`);
  const n = a[t];
  return Object.entries(n.variants).reduce(
    (s, [r]) => ({ ...s, [r]: r }),
    {}
  );
}
function V(t) {
  const n = u(t), i = e(t);
  return c(
    () => Object.keys(n).reduce(
      (s, r) => ({
        ...s,
        [p(r)]({ children: o }) {
          return r === i || r.startsWith("A-") && i === void 0 ? o : null;
        }
      }),
      {}
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
}
function p(t) {
  function n(r) {
    return r.charAt(0).toUpperCase() + r.slice(1);
  }
  return t.split("-").map(n).join("");
}
export {
  e as useActiveVariant,
  V as useVariants,
  u as useVariantsOf
};
