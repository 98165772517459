import type { ReactElement } from "react"
import { useEffect } from "react"

import { localStorage } from "@intergamma/storage"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { useSessionData } from "~/features/session-data/SessionDataContext"

import { getCustomerHashLocalStorageKey } from "~/services/helpers/getCustomerHashLocalStorageKey"

const customerHashLocalStorageKey = getCustomerHashLocalStorageKey()

interface AuthenticatedProps {
  children: ReactElement
}

export function Authenticated({ children }: AuthenticatedProps) {
  const { t } = useTranslation(["urls"])
  const navigate = useNavigate()
  const sessionData = useSessionData()

  useEffect(() => {
    if (!sessionData.isLoggedIn) {
      localStorage.removeItem(customerHashLocalStorageKey)
      navigate(t("urls:/login"), { replace: true }) // Navigate back to home page when customer is invalid
    }
  }, [sessionData, navigate, t])

  if (!sessionData.isLoggedIn) {
    return null
  }

  return children
}
