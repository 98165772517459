import { useSessionData } from "~/features/session-data/SessionDataContext"

/**
 * Renders Mopinion feedback from third party script, only on Logged in pages
 */
export function MopinionFeedback() {
  const { isLoggedIn } = useSessionData()

  if (!isLoggedIn) {
    return null
  }

  return <div id="surveyForm" className="relative mt-4 p-0" />
}
