import { media } from "@intergamma/theme"

import { ChevronRight } from "react-feather"
import styled from "styled-components"

import { Icon } from "~/components/Icon"

import type { LoggedInNavigationItem } from "../hooks/useLoggedInNavigationItems"

import { NavigationLink } from "./NavigationLink"

interface NavigationItemProps {
  item: LoggedInNavigationItem
}

export function NavigationItem({ item }: NavigationItemProps) {
  return (
    <StyledItemContainer>
      <NavigationLink item={item}>
        <Icon icon={item.icon} />
        <span>{item.name}</span>
        <StyledChevron />
      </NavigationLink>
    </StyledItemContainer>
  )
}

const StyledChevron = styled(ChevronRight)`
  stroke: ${({ theme }) => theme.colors.primary};
  transition: 0.2s ease-in-out;
  position: absolute;
  right: 1rem;
  top: calc(50% - 0.5rem);
  height: 1rem;

  ${media.md`
    display: none;
  `}
`

const StyledItemContainer = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  margin: 0;
  position: relative;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey200}`};

  ${media.md`
    border-bottom: none;
    padding: 0.25rem 1rem;
  `}

  &:hover, &:focus {
    ${StyledChevron} {
      right: 0.75rem;
    }
  }
`
