import styled from "styled-components"

export const Breadcrumbs = styled.ol<{ inverse?: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: ${({ inverse }) => (inverse ? "white" : "black")};
  opacity: 0.7;
  transition: opacity 150ms;

  &:hover {
    opacity: 1;
  }
`

export const BreadcrumbItem = styled.li`
  display: inline;
  font-family: ${(props) => props.theme.baseFont};

  &::after {
    content: "›";
    margin: 0 10px;
  }

  &:last-child {
    font-weight: bold;
  }

  &:last-child::after {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: inherit;
    text-decoration: underline;
  }
`
