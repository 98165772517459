import { Suspense } from "react"

import { useTrackLoggedIn } from "@intergamma/account"
import { Footer } from "@intergamma/footer"
import { LoadingHeader } from "@intergamma/header"
import { MainLegalFooter } from "@intergamma/legal-footer"

import { ScrollToTop } from "./components/ScrollToTop"
import { useMyAccountConfig } from "./config/MyAccountConfigContext"
import { useSessionDataQuery } from "./features/session-data/hooks/useSessionDataQuery"
import { SessionDataProvider } from "./features/session-data/SessionDataContext"
import { Header } from "./layouts/Header"
import { LoadingPageLayout } from "./layouts/LoadingPageLayout"
import { Routes } from "./Routes"

export function App() {
  const { data: sessionData } = useSessionDataQuery()
  const {
    baseConfig: { fullBrand },
  } = useMyAccountConfig()

  useTrackLoggedIn({
    formulaId: fullBrand,
    customerId: sessionData?.uid,
    hashedId: sessionData?.hashedId,
  })

  return (
    <Suspense fallback={<LoadingHeader />}>
      <SessionDataProvider>
        <Header />
        <Suspense fallback={<LoadingPageLayout />}>
          <ScrollToTop />
          <Routes />
        </Suspense>
        <Footer>
          <MainLegalFooter />
        </Footer>
      </SessionDataProvider>
    </Suspense>
  )
}
