import { useTranslation } from "react-i18next"

import { ConfirmDialog } from "~/components/ConfirmDialog"

import { NavigationItem } from "./components/NavigationItem"
import { useLoggedInNavigationItems } from "./hooks/useLoggedInNavigationItems"
import { useLogout } from "./hooks/useLogout"

export function LoggedInNavigation() {
  const items = useLoggedInNavigationItems()
  const { logout } = useLogout()
  const { t } = useTranslation(["urls", "logged-in-navigation"])

  return (
    <>
      {items.map((item) => (
        <NavigationItem key={item.name} item={item} />
      ))}
      <ConfirmDialog
        trigger={
          <NavigationItem
            item={{
              isActive: false,
              name: t("logged-in-navigation:logout"),
              icon: "Logout",
            }}
          />
        }
        title={t("logged-in-navigation:accountScreen.logoutTitle")}
        message={t("logged-in-navigation:accountScreen.logoutMessage")}
        action={logout}
        hasDarkPattern={false}
        buttonLabels={{
          confirm: t("logged-in-navigation:accountScreen.logoutPositive"),
          cancel: t("logged-in-navigation:accountScreen.logoutNegative"),
        }}
      />
    </>
  )
}
