import { useEffect } from "react"

import { cn } from "@intergamma/common"
import { Col, Row } from "@intergamma/grid"
import { focusOutline } from "@intergamma/ui/helpers/focusOutline"

import { useQueryClient } from "@tanstack/react-query"
import type { FallbackProps } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { PageLayout } from "~/layouts/PageLayout"

import { CardContent, CardTitle } from "~/components/Card"

import { isAxiosError } from "~/helpers/isAxiosError"

export function ErrorComponent({ error, resetErrorBoundary }: FallbackProps) {
  const queryClient = useQueryClient()
  const { t } = useTranslation(["urls", "errors", "common"])
  const navigate = useNavigate()

  useEffect(() => {
    if (isAxiosError(error) && error.response?.status === 401) {
      queryClient.clear()

      navigate(t("urls:/login"), { replace: true })

      document.body.dispatchEvent(new window.CustomEvent("refreshUser")) // Used to reload user in header

      resetErrorBoundary()
    }
  }, [error, resetErrorBoundary, queryClient, navigate, t])

  if (isAxiosError(error) && error.response?.status === 401) {
    return null
  }

  return (
    <PageLayout
      title={t("errors:UnknownError")}
      heading={t("errors:UnknownError")}
      breadcrumbs={[
        { url: t("urls:/www"), label: "Home" },
        { url: t("urls:/myaccount"), label: t("common:MyAccount") },
        { url: t("urls:/login"), label: t("common:Login") },
      ]}
    >
      <Row>
        <Col $xs={12} $sm={6} $lg={8}>
          <CardTitle>{t("errors:UnknownErrorDescription")}</CardTitle>
          <CardContent>
            <button
              className={cn("underline gamma:text-brand-primary karwei:text-neutral-700", focusOutline)}
              onClick={resetErrorBoundary}
            >
              {t("errors:TryAgain")}
            </button>
          </CardContent>
        </Col>
      </Row>
    </PageLayout>
  )
}
